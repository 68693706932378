function initVideo(popup) {
    const video = popup.find('.video-popup')
    const videoIframe = video.attr('data-video')
    video.append(videoIframe)
}

function deleteVideo(popup) {
    const video = popup.find('.video-popup')
    video.empty()
}
