$('.js-confirmation').on('change', function (e) {
    const target = e.target
    disableFormSubmissionButton(target)
})

function disableFormSubmissionButton(input) {
    const isChecked = input.checked
    const form = input.closest('.js-form')
    const formSubmitButton = form.querySelector('.js-form-submit')

    formSubmitButton.toggleAttribute('disabled', !isChecked)
}

function formSubmissionIsConfirmed(form) {
    const inputConfirmation = form.querySelector('.js-confirmation')
    return inputConfirmation.checked
}
