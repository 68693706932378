$(window).on('load', function () {
    const imageListSelector = '.js-image-list-animated'
    const imageListImageSelector = '.js-image-list-animated__image'
    const imageList = $(imageListSelector)
    imageList.each(function (index, item) {
        const images = $(item).find(imageListImageSelector)
        images.each(function (index, imageElement) {
            setImageOffset(index, imageElement, images.length)
        })
    })

    function setImageOffset(imageElementIndex, imageElement, imagesCount) {
        const offset = (imageElementIndex / (imagesCount - 1)) * -100
        $(imageElement).css('transform', 'translateY(' + offset + '%)')
        $(imageElement).css('top', -offset + '%')
    }
})
