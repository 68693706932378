function openPopup() {
    const target = $(this)
    const popupId = target.attr('data-popup')
    if (!popupId) return

    const popup =  $('#' + popupId)

    closeOpenedPopups()

    if (popup.hasClass('popup-wrapper--video')) {
        initVideo(popup)
    }

    popup.removeClass('hidden')
    $('body').addClass('popup-active')
}

function closePopup() {
    const target = $(this)
    const popup = target.closest('.popup-wrapper')
    if (!popup) return

    popup.addClass('hidden')
    $('body').removeClass('popup-active')

    if (popup.hasClass('popup-wrapper--video')) {
        deleteVideo(popup)
    }
}

function closeOpenedPopups() {
    $('.popup-wrapper:not(.hidden)').addClass('hidden')
}

$('.js-open-popup').on('click', openPopup)
$('.js-close-popup').on('click', closePopup)
