function initPhoneMask() {
    const maskOptions = {
        mask: [
            {
                mask: '+{7} (000) 000-00-00'
            }
        ]
    };

    $('.js-input-phone').each(function (index, input) {
        IMask(input, maskOptions);
    })
}

initPhoneMask()
