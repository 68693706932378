function togglePlaceholderInputs(e) {
    const target = $(e.target)
    const inputParent = target.closest('.js-input')
    inputParent.toggleClass('active', !!target.val())
}

function disablePlaceholderInputs(inputs) {
    inputs.each(function (index, input) {
        const inputParent = input.closest('.js-input')
        if (inputParent) {
            $(inputParent).removeClass('active')
        }
    })
}

$('.js-input-field').on('blur', togglePlaceholderInputs)
