function submitForm(e) {
    e.preventDefault()

    if (!formSubmissionIsConfirmed(this)) return

    const form = $(this)
    const formAction = form.attr('action')
    const formData = new FormData(this)
    const formSubmitButton = form.find('.js-form-submit')

    formSubmitButton.attr("disabled", true)

    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: formAction,
        data: formData,
        processData: false,
        contentType: false,
        success: function() {
            showFormPopup(form)
            resetForm(form)
        },
        error: function(response) {
            const errors = JSON.parse(response.responseText).data.errors
            parseErrors(errors, form)
        },
        complete: function () {
            formSubmitButton.attr("disabled", false)
        }
    })
}

function showFormPopup(form) {
    if (form.attr('data-popup')) {
        const openFormPopup = openPopup.bind(form)
        openFormPopup()
    }
}

function resetForm(form) {
    form.get(0).reset()
    disablePlaceholderInputs(form.find('.js-input'))
    setTimeout(function () {
        disableFormSubmissionButton(form.find('.js-confirmation').get(0))
    }, 100)
}

function parseErrors (errors, form) {
    for (var error in errors) {
        const input = form.find('[name=' + error + ']')
        const inputParent = input.closest('.js-input')

        if (input && inputParent) {
            inputParent.addClass('error')
            const fieldError = inputParent.find('.js-error-message')
            fieldError.removeClass('hidden')
            fieldError.text(errors[error].message)
        }
    }
}

function removeErrors() {
    const input = $(this)
    const inputParent = input.closest('.js-input')

    if (inputParent.hasClass('error')) {
        const fieldError = inputParent.find('.js-error-message')
        inputParent.removeClass('error')
        fieldError.addClass('hidden')
    }
}

$('.js-form').on('submit', submitForm)
$('.js-input-field').on('change', removeErrors)
$('.js-input-field').on('input', removeErrors)
