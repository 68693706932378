function hideMenuItems() {
    const menu = document.querySelector('.js-header-menu')
    const menuItems = menu.querySelectorAll('.js-header-menu-item')
    const moreMenu = document.querySelector('.js-header-hidden-menu')
    const moreMenuItems = moreMenu.querySelectorAll('.js-header-hidden-menu-item')
    const countMenu = moreMenu.querySelector('.js-header-hidden-menu-count')
    const hiddenMenuItems = menu.querySelectorAll('.js-header-menu-item.hidden')

    for (var i = 0; i < hiddenMenuItems.length; i++) {
        hiddenMenuItems[i].classList.remove('hidden')
    }

    var hiddenItemsCount = 0

    for (var i = menuItems.length - 1; i >= 0 ; i--) {
        if (menuItems[i].offsetTop > 0) {
            moreMenu.classList.remove('hidden')
            menuItems[i].classList.add('hidden')
            ++hiddenItemsCount
            moreMenuItems[moreMenuItems.length - hiddenItemsCount].classList.remove('hidden')
            countMenu.innerHTML = hiddenItemsCount.toString()
        }
    }

    if (!hiddenItemsCount) {
        moreMenu.classList.add('hidden')
    } else {
        moreMenu.classList.remove('hidden')
    }
}

document.addEventListener("DOMContentLoaded", function () {
    hideMenuItems()
    window.addEventListener('resize', hideMenuItems)
});
