const swiper = new Swiper('.js-gallery', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    breakpoints: {
        800: {
            spaceBetween: 56
        }
    },
});
